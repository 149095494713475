export interface WebsiteConfig {
  title: string;
  description: string;
  coverImage: string;
  logo: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  facebook: string;
  twitter: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
}

const config: WebsiteConfig = {
  title: 'michaelwarf.com',
  description: 'personal thoughts, public posts.',
  coverImage: 'img/ghost_back.jpg',
  logo: 'img/warf_blog.png',
  siteUrl: 'https://michaelwarf.com',
  facebook: 'https://www.facebook.com/lethbridge',
  twitter: 'https://twitter.com/warfeous',
  showSubscribe: true,
  mailchimpAction: 'https://coalbanks.us18.list-manage.com/subscribe/post?u=d3a0df8e83ed12c817ccedcb1&amp;id=faaf7df1f6',
  mailchimpName: 'b_d3a0df8e83ed12c817ccedcb1_faaf7df1f6',
};

export default config;
